// src/analytics.js

// Google Analytics 4 initialization
export const GA_MEASUREMENT_ID = 'G-Z0M4W6ZRVK';

// Initialize Google Analytics
export const initGA = () => {
  if (typeof window !== 'undefined') {
    console.log('Initializing Google Analytics...');
    // Load the Google Analytics script
    const script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`;
    script.async = true;
    document.head.appendChild(script);

    // Initialize the dataLayer
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', GA_MEASUREMENT_ID);

    // Make gtag available globally
    window.gtag = gtag;
    console.log('Google Analytics initialized successfully');
  }
};

// Track page views
export const pageview = (url) => {
  if (typeof window !== 'undefined' && window.gtag) {
    console.log('Tracking pageview:', url);
    window.gtag('config', GA_MEASUREMENT_ID, {
      page_path: url,
    });
  }
};

// Track events
export const event = ({ action, category, label, value }) => {
  if (typeof window !== 'undefined' && window.gtag) {
    console.log('Tracking event:', { action, category, label, value });
    window.gtag('event', action, {
      event_category: category,
      event_label: label,
      value: value
    });
  }
};
