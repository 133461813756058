import React, { useEffect } from 'react';
import AppNavigation from "./app-navigation/AppNavigation";
import './global.css';
import { initGA, pageview } from './analytics';

function App() {
  useEffect(() => {
    initGA();
    // Track initial page view
    pageview(window.location.pathname + window.location.search);
  }, []);

  return <AppNavigation />;
}

export default App;
