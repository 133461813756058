import React from "react";
import { useNavigate } from "react-router-dom";
const Footer = () => {
  const navigate = useNavigate();
  const handleHakkimizdaClick = () => {
    navigate("/");
    setTimeout(() => {
      const element = document.getElementById('hakkimizda');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100);
  };
  return (
    <>
      {/* <!-- footer area start --> */}
      <footer>
        <div className="tp-footer__area black-bg">
          <div className="tp-footer">
            {/* <!-- main-footer start  --> */}
            <div className="tp-footer__main black-bg-custom">
              <div className="container">
                <div className="tp-footer-border pt-60">
                  <div className="row">
                    <div className="col-lg-4 col-md-6 footer-column">
                      <div className="tp-footer__widget  pb-30">
                        <h3 className="tp-footer__widget-title">
                          Menü
                        </h3>
                        <ul>
                          <li>
                            <a href="/">Ana Sayfa</a>
                          </li>
                          <li>
                            <a onClick={handleHakkimizdaClick} href="#">Hakkımızda & Kurucumuz</a>
                          </li>
                          <li>
                            <a href="/calisma-alanlarimiz">Çalışma Alanlarımız</a>
                          </li>
                          <li>
                            <a href="#">İletişim</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {/* <div className="col-lg-4 col-md-6">
                      <div className="tp-footer__widget pb-30">
                        <h3 className="tp-footer__widget-title">Hizmetlerimiz</h3>
                        <ul>
                          <li>
                            <a href="#">İş ve Sosyal Güvenlik Hukuku</a>
                          </li>
                          <li>
                            <a href="#">İcra ve İflas Hukuku</a>
                          </li>
                          <li>
                            <a href="#">Aile Hukuku</a>
                          </li>
                          <li>
                            <a href="#">Gayrimenkul Hukuku</a>
                          </li>
                          <li>
                            <a href="#">Sözleşmeler Hukuku</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 ">
                      <div className="tp-footer__widget  pb-30">
                        <h3 className="tp-footer__widget-title">Sosyal Medya</h3>
                        <ul>
                          <li>
                            <a href="#">X</a>
                          </li>
                          <li>
                            <a href="#">LinkedIn</a>
                          </li>
                          <li>
                            <a href="#">Instagram</a>
                          </li>
                        </ul>
                      </div>
                    </div> */}
                    <div className="col-lg-4 col-md-6 footer-column">
                      <div className="tp-footer__widget  pb-30">
                        <h3 className="tp-footer__widget-title">İletİşİm</h3>
                        <ul>
                          <li>
                            <a href="#">
                              Esentepe Mah. Talatpaşa Cad.
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              No: 5/1 Şişli/İstanbul
                            </a>
                          </li>
                          <li>
                            <a href="tel:+88015569569365">+90 (552) 112 28 35</a>
                          </li>
                          <li>
                            <a href="mailto:info@uysalkaraca.com">
                              info@uysalkaraca.com
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 footer-column">
                      <div className="tp-footer__widget  pb-30">
                        <h3 className="tp-footer__widget-title">Çalışma Saatlerİ</h3>
                        <ul>
                          <li>
                            <span> Çalışma Saatleri: 09:00 - 18:00</span>
                          </li>
                          <li>
                            <span> Pazartesi - Cuma</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- main footer end  --> */}
          </div>
        </div>

        {/* <!-- footer copy right --> */}
        <div className="top-footer-copyright pt-30 black-bg-custom">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-12">
                <div className="tp-copyrigh-text mb-30">
                  <span>
                    © {new Date().getFullYear()} Themos Yazılım. Tüm hakları saklıdır.
                  </span>
                </div>
              </div>
              <div className="col-md-4 col-12">
              </div>
            </div>
          </div>
        </div>
        {/* <!-- footer copyright end  --> */}
      </footer>
      {/* <!-- footer area end --> */}
    </>
  );
};

export default Footer;
