import React from 'react';
import { Route, Routes, useLocation } from "react-router-dom";  // Updated this line
// internal
import BackToTop from '../components/BackToTop';
import ContextProvider from '../components/context/ContextProvider';
import Home from '../components/Home/Home';
import About from '../components/Pages/About/About';
import Blog from '../components/Pages/Blog/Blog';
import BlogDetails from '../components/Pages/BlogDetails/BlogDetails';
import ContactUs from '../components/Pages/ContactUs/ContactUs';
import FAQ from '../components/Pages/FAQ/FAQ';
import Portfolio from '../components/Pages/Portfolio/Portfolio';
import PortfolioDetails from '../components/Pages/PortfolioDetails/PortfolioDetails';
import Price from '../components/Pages/Price/Price';
import Service from '../components/Pages/Service/Service';
import ServiceDetails from '../components/Pages/ServiceDetails/ServiceDetails';
import TeamDetails from '../components/Pages/TeamDetails/TeamDetails';
import TeamPage from '../components/Pages/TeamPage/TeamPage';
import IsveSosyalGuvenlikHukuku from '../components/Pages/Service/is-ve-sosyal-guvenlik-hukuku';
import AileHukukuPage from '../components/Pages/Service/aile-hukuku';
import GayrimenkulHukukuPage from '../components/Pages/Service/gayrimenkul-hukuku';
import SozlesmelerHukukuPage from '../components/Pages/Service/sozlesmeler-hukuku';
import TicaretveSirketlerHukukuPage from '../components/Pages/Service/ticaret-ve-sirketler-hukuku';
import CezaHukukuPage from '../components/Pages/Service/ceza-hukuku';
import GocVeYabancilarHukukuPage from '../components/Pages/Service/goc-ve-yabancilar-hukuku';
import TuketiciHukukuPage from '../components/Pages/Service/tuketici-hukuku';
import MirasHukukuPage from '../components/Pages/Service/miras-hukuku';
import IdareHukukuPage from '../components/Pages/Service/idare-hukuku';
import IcraIflasHukukuPage from '../components/Pages/Service/icra-iflas-hukuku';
import DavaTakibiPage from '../components/Pages/Service/dava-takibi';
import CookieBanner from '../components/Cookie/CookieBanner';
import Cookie from '../components/Pages/Cookie/Cookie';
import { pageview } from '../analytics';
import { useEffect } from 'react';  // Remove useLocation from this import




const AppNavigation = () => {
  const location = useLocation();
  useEffect(() => {
    pageview(location.pathname);
  }, [location]);

  return (
    <>
    <CookieBanner />
    <ContextProvider>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/hakkimizda" element={<About />} />
        <Route path="/team" element={<TeamPage/>} />
        <Route path="/team-details" element={<TeamDetails />} />
        <Route path="/service" element={<Service />} />
        <Route path="/service-details" element={<ServiceDetails />} />
        <Route path="/calisma-alanlarimiz" element={<Price/>} />
        <Route path="/iletisim" element={<ContactUs />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/portfolio-details" element={<PortfolioDetails />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog-details" element={<BlogDetails />} />
        <Route path="/is-ve-sosyal-guvenlik-hukuku" element={<IsveSosyalGuvenlikHukuku />} />
        <Route path="/aile-hukuku" element={<AileHukukuPage />} />
        <Route path="/gayrimenkul-hukuku" element={<GayrimenkulHukukuPage />} />
        <Route path="/sozlesmeler-hukuku" element={<SozlesmelerHukukuPage />} />
        <Route path="/ticaret-ve-sirketler-hukuku" element={<TicaretveSirketlerHukukuPage />} />
        <Route path="/ceza-hukuku" element={<CezaHukukuPage />} />
        <Route path="/goc-ve-yabancilar-hukuku" element={<GocVeYabancilarHukukuPage />} />
        <Route path="/tuketici-hukuku" element={<TuketiciHukukuPage />} />
        <Route path="/miras-hukuku" element={<MirasHukukuPage />} />
        <Route path="/idare-hukuku" element={<IdareHukukuPage />} />
        <Route path="/icra-iflas-hukuku" element={<IcraIflasHukukuPage />} />
        <Route path="/dava-takibi" element={<DavaTakibiPage />} />
        <Route path="/cerezler" element={<Cookie />} />
      </Routes>
      <BackToTop/>
    </ContextProvider>
    </>
  );
};

export default AppNavigation;